import {useEffect} from "react";

const ColorQuestion = ({element}) => {
    const question = document.querySelector('#questionDialogBg')
    const pinnedQuestion = document.querySelector('#a'+element.id)
    //const pinnedQuestion = document.querySelector('#pinnedQuestionDialogBg')

    if(!!question) {
        question.style.backgroundColor = element.color;
    }

    if(!!pinnedQuestion) {
        pinnedQuestion.style.backgroundColor = element.color;
    }

    useEffect(() => {
        // Funzione per evitare lo standby dello schermo
        const preventScreenStandby = () => {
            // Verifica se il browser supporta la funzionalità di prevenzione dello standby
            if ('wakeLock' in navigator) {
                navigator.wakeLock.request('screen').then((wakeLock) => {
                    // Lo schermo rimarrà attivo finché il wakeLock non viene rilasciato
                    return () => {
                        wakeLock.release();
                    };
                });
            }
        };

        // Attiva la prevenzione dello standby all'avvio del componente
        preventScreenStandby();

        // Pulisci l'effetto quando il componente viene smontato
        return () => {
            // Rilascia il wakeLock per permettere allo schermo di andare in standby
            if ('wakeLock' in navigator) {
                navigator.wakeLock.release();
            }
        };
    }, []);

    return (
        <div/>
    )
}

export default ColorQuestion